import type _fs from "fs";
import type { Stats } from "fs";
import { posixPath } from "./posixPath";
import { split } from "./paths";
import { LogFn } from "./merge-workspace/merge";

export type Fs = typeof _fs;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R
  ? (...args: P) => R
  : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function wrapFsUtil<TFn extends (...args: any) => any>(
  fn: TFn,
  getFs: () => Fs
): OmitFirstArg<TFn> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ((...args: any[]) => {
    const fs = getFs();
    return fn(fs, ...args);
  }) as OmitFirstArg<TFn>;
}

/**
 * @param {string} allowedExtension - expects extension with leading dot (e.g. ".yaml")
 */
export const copyFilesDeep = async (
  fs: Fs,
  sourceDirectory: string,
  targetDirectory: string,
  allowedExtension?: string
): Promise<string[]> => {
  const files: string[] = [];

  if (!(await exists(fs, sourceDirectory))) {
    return files;
  }

  const copy = async (sourceDir: string, targetDir: string) => {
    if (!(await isDir(fs, targetDir))) {
      await mkdir(fs, targetDir);
    }

    const localFiles = await fs.promises.readdir(sourceDir);

    for (const localFile of localFiles) {
      const sourceLocation = posixPath.join(sourceDir, localFile);
      const targetLocation = posixPath.join(targetDir, localFile);

      if (await isDir(fs, sourceLocation)) {
        await mkdir(fs, targetLocation);
        await copy(sourceLocation, targetLocation);
        continue;
      }

      let isValidFile = true;

      if (
        allowedExtension &&
        !sourceLocation
          .toLocaleLowerCase()
          .endsWith(allowedExtension.toLocaleLowerCase())
      ) {
        isValidFile = false;
      }

      if (isValidFile) {
        const fileContent = await fs.promises.readFile(sourceLocation);
        await fs.promises.writeFile(targetLocation, fileContent);
        files.push(localFile);
      }
    }
  };

  await copy(sourceDirectory, targetDirectory);

  return files;
};

export const isDir = async (fs: Fs, location: string): Promise<boolean> => {
  try {
    const stats = (await fs.promises.stat(location)) as Stats;
    return stats.isDirectory();
  } catch (error) {
    return false;
  }
};

export const mkdir = async (fs: Fs, location: string): Promise<void> => {
  if (await exists(fs, location)) {
    return;
  }

  const parts = split(location);
  parts.pop();

  const joined = posixPath.join(...parts);
  if (joined === ".") return;

  await mkdir(fs, joined);

  try {
    await fs.promises.mkdir(location, { recursive: true });
  } catch (err) {
    const errObj = err as Record<"code" | "message", string>;

    if (errObj.code !== "EEXIST") {
      throw new Error(errObj.message);
    }
  }
};

export const exists = async (fs: Fs, location: string): Promise<boolean> => {
  try {
    await fs.promises.stat(location);
    return true;
  } catch (error) {
    return false;
  }
};

export const safeRead = async (
  fs: Fs,
  location: string,
  log?: LogFn
): Promise<string | undefined> => {
  try {
    return await fs.promises.readFile(location, { encoding: "utf8" });
  } catch (e) {
    log?.(`error reading file: ${e}`);
    return undefined;
  }
};

export const safeWrite = async (
  fs: Fs,
  location: string,
  content: string,
  log?: LogFn
): Promise<void> => {
  try {
    await fs.promises.writeFile(location, content, { encoding: "utf8" });
  } catch (e) {
    log?.(`error writing file: ${e}`);
    return;
  }
};
