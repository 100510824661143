import {
  RepositoryAccessFailure,
  UserSettings,
} from "@sapiens-digital/ace-designer-common";
import { DesignerConfig } from "@sapiens-digital/ace-designer-common/lib/model/designerSettings";
import crypto from "crypto";
import { Stats } from "fs";
import path from "path";

import { Result } from "../model/validation";

import { getRemoteInfo } from "./git-utils";
import { getWorkspaceFS } from "./workspace";
import { container, symbols } from ".";

export interface LoadDesignerSettings {
  (): Promise<UserSettings>;
}

export interface SaveDesignerSettings {
  (settings: UserSettings): Promise<RepositoryAccessFailure | undefined>;
}

export interface GetEnvironmentVariables {
  (): Promise<DesignerConfig>;
}

export const loadDesignerSettings: LoadDesignerSettings = async () =>
  await container.get<LoadDesignerSettings>(symbols.LoadDesignerSettings)();

export const getEnvironmentVariables: GetEnvironmentVariables = async () =>
  await container.get<GetEnvironmentVariables>(
    symbols.GetEnvironmentVariables
  )();

export const saveDesignerSettings: SaveDesignerSettings = async (
  settings: UserSettings
) =>
  container.get<SaveDesignerSettings>(symbols.SaveDesignerSettings)(settings);

export const validateWorkspacesLocation = async (
  location: string
): Promise<Result> => {
  const fs = getWorkspaceFS();
  const bufferSize = 20;
  const dirName = crypto.randomBytes(bufferSize).toString("hex");
  const fileName = crypto.randomBytes(bufferSize).toString("hex");

  try {
    const locationExists = (await fs.promises.stat(location)) as Stats;

    if (!locationExists.isDirectory()) {
      return {
        valid: false,
        message: `Location ${location} is not a directory.`,
      };
    }
  } catch (error) {
    return {
      valid: false,
      message: `Directory ${location} does not exist.`,
      error: error,
    };
  }

  const dirPath = path.join(location, dirName);

  try {
    await fs.promises.mkdir(path.join(dirPath));
  } catch (error) {
    return {
      valid: false,
      message: `Failed to create test directory ${dirPath}`,
      error: error,
    };
  }

  const filePath = path.join(dirPath, fileName);

  try {
    await fs.promises.writeFile(filePath, filePath);
    await fs.promises.unlink(filePath);
    await fs.promises.rmdir(dirPath);
  } catch (error) {
    return {
      valid: false,
      message: `Failed to create/read/delete test file ${filePath}`,
      error: error,
    };
  }

  return { valid: true };
};

export const validateRepositoryAccess = async (
  repositoryUrl: string,
  token: string,
  username?: string
): Promise<Result> => {
  try {
    const data = await getRemoteInfo(repositoryUrl, token, username);

    if (data.refs === undefined) {
      return {
        valid: false,
        message: `Cannot access refs.`,
      };
    }
  } catch (error) {
    return {
      valid: false,
      message: `Failed to access ${repositoryUrl}.`,
      error: error,
    };
  }

  return { valid: true };
};

export const validateDefaultBranch = async (
  repositoryUrl: string,
  token: string,
  branch: string,
  username?: string
): Promise<Result> => {
  try {
    const data = await getRemoteInfo(repositoryUrl, token, username);

    if (
      data.refs === undefined ||
      data.refs.heads === undefined ||
      data.refs.heads[branch] === undefined
    ) {
      return {
        valid: false,
        message: `Default branch ${branch} does not exist.`,
      };
    }
  } catch (error) {
    return {
      valid: false,
      message: `Failed to access ${repositoryUrl}.`,
      error: error,
    };
  }

  return { valid: true };
};
