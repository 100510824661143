import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { isRejected } from "@reduxjs/toolkit";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Paper } from "@mui/material";
import {
  AppBar,
  Dialog,
  DialogContent,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions/transition";
import {
  RepositoryAccessFailure,
  UserSettings,
} from "@sapiens-digital/ace-designer-common";
import { SettingsManager } from "services/settingsManager";
import { selectIsLocalRepositorySet } from "store/designer/selectors";

import { RUNTIME_SERVER_DEFAULT_PORT } from "../constant";
import {
  validateDefaultBranch,
  validateRepositoryAccess,
  validateWorkspacesLocation,
} from "../services/settings";
import { Environment, getEnvironment } from "../services/workspace";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { closeSettings, setDesignerLoading } from "../store/designer/reducers";
import {
  detectRepositoryChangeThunk,
  saveDesignerSettingsThunk,
} from "../store/settings/actions";

import { notifyError } from "./utils/notify";
import { canParseToNumber, isEmpty, isUrlValid } from "./validators/settings";
import ButtonComponent from "./ButtonComponent";

const rootStyle = { margin: "4px", padding: "4px" };

const TransitionComponent = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DesignerSettings: React.FC = () => {
  const isElectron = getEnvironment() === Environment.Electron;

  const dispatch = useAppDispatch();
  const open = useSelector<RootState, boolean>(
    (state) => state.designer.settingsOpen
  );
  const [isLoading, setIsLoading] = useState(false);

  const settings = useSelector<RootState, UserSettings>(
    (state) => state.settings
  );
  const isLocalRepositorySet = useAppSelector(selectIsLocalRepositorySet);

  const [workspacesLocation, setWorkspacesLocation] = useState(
    settings.workspacesLocation || ""
  );
  const [repositoryUrl, setRepositoryUrl] = useState(
    settings.repositoryUrl || ""
  );

  const [fullName, setFullName] = useState(settings.fullName || "");
  const [fullNameError, setFullNameError] = useState(false);
  const [fullNameHelperText, setFullNameHelperText] = useState("");
  const [email, setEmail] = useState(settings.email || "");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [editRepositoryToken, setEditRepositoryToken] = useState(false);
  const isUpdatingRepositoryToken =
    editRepositoryToken || !isLocalRepositorySet;

  const [repositoryUsername, setRepositoryUsername] = useState(
    settings.repositoryUsername || ""
  );
  const [repositoryToken, setRepositoryToken] = useState(
    settings.repositoryToken || ""
  );
  const [repositoryDefaultBranch, setRepositoryDefaultBranch] = useState(
    settings.repositoryDefaultBranch || ""
  );

  const [repositoryWorkspacePath, setRepositoryWorkspacePath] = useState(
    settings.repositoryWorkspacePath || ""
  );
  const [runtimeServerPort, setRuntimeServerPort] = useState(
    String(settings.runtimeServerPort || RUNTIME_SERVER_DEFAULT_PORT)
  );

  const [workspacesLocationHelperText, setWorkspacesLocationHelperText] =
    useState("");
  const [repositoryUrlHelperText, setRepositoryUrlHelperText] = useState("");
  const [repositoryTokenHelperText, setRepositoryTokenHelperText] =
    useState("");
  const [
    repositoryDefaultBranchHelperText,
    setRepositoryDefaultBranchHelperText,
  ] = useState("");

  const [
    repositoryWorkspacePathHelperText,
    setRepositoryWorkspacePathHelperText,
  ] = useState("");

  const [runtimeServerPortHelperText, setRuntimeServerPortHelperText] =
    useState("");

  const [workspacesLocationError, setWorkspacesLocationError] = useState(false);
  const [repositoryUrlError, setRepositoryUrlError] = useState(false);
  const [repositoryTokenError, setRepositoryTokenError] = useState(false);
  const [repositoryDefaultBranchError, setRepositoryDefaultBranchError] =
    useState(false);
  const [repositoryWorkspacePathError, setRepositoryWorkspacePathError] =
    useState(false);
  const [runtimeServerPortError, setRuntimeServerPortError] = useState(false);

  const firstRender = useRef(true);
  const { featurePersistentSettings } = SettingsManager.getDesignerConfigs();

  // Update state when settings change in Application state or dialog is opened
  useEffect(() => {
    setWorkspacesLocation(settings.workspacesLocation || "");
    setRuntimeServerPort(
      String(settings.runtimeServerPort || RUNTIME_SERVER_DEFAULT_PORT)
    );
    setRepositoryUrl(settings.repositoryUrl || "");
    setRepositoryUsername(settings.repositoryUsername || "");
    setRepositoryToken(settings.repositoryToken || "");
    setRepositoryDefaultBranch(settings.repositoryDefaultBranch || "");
    setFullName(settings.fullName || "");
    setEmail(settings.email || "");
    setRepositoryWorkspacePath(settings.repositoryWorkspacePath || "");
  }, [settings, open]);

  useEffect(() => {
    if (!featurePersistentSettings) {
      return;
    }

    setFullNameError(false);
    setFullNameHelperText("");
    setEmailError(false);
    setEmailHelperText("");
    setWorkspacesLocationError(false);
    setWorkspacesLocationHelperText("");
    setRepositoryUrlError(false);
    setRepositoryUrlHelperText("");
    setRepositoryTokenError(false);
    setRepositoryTokenHelperText("");
    setRepositoryDefaultBranchError(false);
    setRepositoryDefaultBranchHelperText("");
    setRepositoryWorkspacePathError(false);
    setRepositoryWorkspacePathHelperText("");
    setRuntimeServerPortError(false);
    setRuntimeServerPortHelperText("");
    setEditRepositoryToken(false);
  }, [open, featurePersistentSettings]);

  useEffect(() => {
    setRepositoryTokenError(false);
    setRepositoryTokenHelperText("");
  }, [editRepositoryToken]);

  // UI field basic validation
  useEffect(() => {
    if (firstRender.current === true) {
      return;
    }

    isEmpty(
      workspacesLocation,
      setWorkspacesLocationHelperText,
      setWorkspacesLocationError
    );
  }, [workspacesLocation]);

  useEffect(() => {
    if (firstRender.current === true) {
      return;
    }

    isEmpty(repositoryUrl, setRepositoryUrlHelperText, setRepositoryUrlError);
    isUrlValid(
      repositoryUrl,
      setRepositoryUrlHelperText,
      setRepositoryUrlError
    );
  }, [repositoryUrl]);

  useEffect(() => {
    if (firstRender.current === true) {
      return;
    }

    isEmpty(
      repositoryToken,
      setRepositoryTokenHelperText,
      setRepositoryTokenError
    );
  }, [repositoryToken]);

  useEffect(() => {
    if (firstRender.current === true) {
      return;
    }

    isEmpty(fullName, setFullNameHelperText, setFullNameError);
  }, [fullName]);

  useEffect(() => {
    if (firstRender.current === true) {
      return;
    }

    isEmpty(email, setEmailHelperText, setEmailError);
  }, [email]);

  useEffect(() => {
    if (firstRender.current === true) {
      return;
    }

    isEmpty(
      repositoryDefaultBranch,
      setRepositoryDefaultBranchHelperText,
      setRepositoryDefaultBranchError
    );
  }, [repositoryDefaultBranch]);

  useEffect(() => {
    if (firstRender.current === true) {
      return;
    }

    canParseToNumber(
      runtimeServerPort,
      setRuntimeServerPortHelperText,
      setRuntimeServerPortError
    );
  }, [runtimeServerPort]);

  useEffect(() => {
    if (firstRender.current === true) {
      return;
    }

    isEmpty(
      repositoryWorkspacePath,
      setRepositoryWorkspacePathHelperText,
      setRepositoryWorkspacePathError
    );
  }, [repositoryWorkspacePath]);

  useEffect(() => {
    firstRender.current = false;
  });

  const isRepositoryTokenInvalid = (): boolean => {
    const isTokenEmpty = isEmpty(
      repositoryToken,
      setRepositoryTokenHelperText,
      setRepositoryTokenError
    );

    if (featurePersistentSettings && !isUpdatingRepositoryToken) {
      return false;
    }

    return isTokenEmpty;
  };

  const getRepositoryTokenToSave = (): string => {
    if (featurePersistentSettings) {
      return isUpdatingRepositoryToken ? repositoryToken : "";
    }

    return repositoryToken;
  };

  const onSave = async () => {
    // All fields have to be validated regardless if any of them is valid or invalid
    let notValid = false;

    if (isElectron) {
      notValid =
        canParseToNumber(
          runtimeServerPort,
          setRuntimeServerPortHelperText,
          setRuntimeServerPortError
        ) || notValid;

      notValid =
        isEmpty(
          workspacesLocation,
          setWorkspacesLocationHelperText,
          setWorkspacesLocationError
        ) || notValid;
    }

    notValid =
      isEmpty(
        repositoryUrl,
        setRepositoryUrlHelperText,
        setRepositoryUrlError
      ) || notValid;
    notValid = isRepositoryTokenInvalid() || notValid;
    notValid =
      isEmpty(
        repositoryDefaultBranch,
        setRepositoryDefaultBranchHelperText,
        setRepositoryDefaultBranchError
      ) || notValid;
    notValid =
      isUrlValid(
        repositoryUrl,
        setRepositoryUrlHelperText,
        setRepositoryUrlError
      ) || notValid;

    notValid =
      isEmpty(fullName, setFullNameHelperText, setFullNameError) || notValid;

    notValid = isEmpty(email, setEmailHelperText, setEmailError) || notValid;

    notValid =
      isEmpty(
        repositoryWorkspacePath,
        setRepositoryWorkspacePathHelperText,
        setRepositoryWorkspacePathError
      ) || notValid;

    if (notValid) {
      return;
    }

    if (isElectron) {
      const locationResult = await validateWorkspacesLocation(
        workspacesLocation
      );

      if (locationResult.valid === false) {
        setWorkspacesLocationHelperText(
          locationResult.message ?? "Failed to validate provided location."
        );
        setWorkspacesLocationError(true);
        return;
      }
    }

    if (!featurePersistentSettings) {
      const repositoryResult = await validateRepositoryAccess(
        repositoryUrl,
        repositoryToken,
        repositoryUsername
      );

      if (repositoryResult.valid === false) {
        setRepositoryUrlHelperText(
          repositoryResult.message ?? "Failed to validate repository."
        );
        setRepositoryUrlError(true);
        return;
      }

      const branchResult = await validateDefaultBranch(
        repositoryUrl,
        repositoryToken,
        repositoryDefaultBranch,
        repositoryUsername
      );

      if (branchResult.valid === false) {
        setRepositoryDefaultBranchHelperText(
          branchResult.message ?? "Failed to validate default branch."
        );
        setRepositoryDefaultBranchError(true);
        return;
      }
    }

    const oldSettings = settings;
    setIsLoading(true);
    const saveDesignerSettingsActionResult = await dispatch(
      saveDesignerSettingsThunk({
        ...settings,
        workspacesLocation,
        repositoryUrl,
        repositoryUsername,
        repositoryToken: getRepositoryTokenToSave(),
        repositoryWorkspacePath,
        repositoryDefaultBranch,
        runtimeServerPort: parseInt(runtimeServerPort),
        fullName,
        email,
      })
    );
    setIsLoading(false);

    if (isRejected(saveDesignerSettingsActionResult)) {
      const error = saveDesignerSettingsActionResult.payload;

      if (featurePersistentSettings) {
        if (error === RepositoryAccessFailure.NotFoundBranch) {
          setRepositoryDefaultBranchHelperText(
            "Failed to validate default branch."
          );
          setRepositoryDefaultBranchError(true);
          return;
        }

        // Fallback error - most likely authorization issue or invalid URL
        setRepositoryUrlHelperText("Failed to validate repository.");
        setRepositoryUrlError(true);
        return;
      } else {
        throw error;
      }
    }

    dispatch(closeSettings());

    dispatch(setDesignerLoading(true));
    const detectRepositoryChangeResult = await dispatch(
      detectRepositoryChangeThunk(oldSettings)
    );
    dispatch(setDesignerLoading(false));
    notifyError(detectRepositoryChangeResult, dispatch);
  };

  const handleClose = () => {
    dispatch(closeSettings());
  };

  const getRepositoryTokenStyle = () => {
    if (!isLocalRepositorySet) {
      return {};
    }

    return !repositoryTokenError ? { height: "56px" } : { minHeight: "56px" };
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      TransitionComponent={TransitionComponent}
    >
      <AppBar style={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            onClick={() => {
              dispatch(closeSettings());
            }}
            color="inherit"
            data-testid="settings-close-icon"
          >
            <CloseIcon />
          </IconButton>

          <Typography style={{ flex: 1 }}>Settings</Typography>
          <ButtonComponent
            variant="contained"
            onClick={onSave}
            loading={isLoading}
          >
            Save
          </ButtonComponent>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Paper>
          <Box m={2} p={2}>
            {isElectron && (
              <TextField
                variant="standard"
                sx={rootStyle}
                fullWidth
                label="Location of ACE workspaces (applied after restart)"
                value={workspacesLocation}
                onChange={(event) => setWorkspacesLocation(event.target.value)}
                helperText={workspacesLocationHelperText}
                error={workspacesLocationError}
              />
            )}
            <TextField
              autoComplete="off"
              variant="standard"
              sx={rootStyle}
              fullWidth
              label="Repository URL"
              value={repositoryUrl}
              onChange={(event) => setRepositoryUrl(event.target.value)}
              helperText={repositoryUrlHelperText}
              error={repositoryUrlError}
            />
            <TextField
              autoComplete="off"
              variant="standard"
              sx={rootStyle}
              fullWidth
              label="Repository Username"
              value={repositoryUsername}
              onChange={(event) => setRepositoryUsername(event.target.value)}
            />
            {featurePersistentSettings ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  ...getRepositoryTokenStyle(),
                  marginTop: "4px",
                  marginBottom: "4px",
                }}
              >
                {isLocalRepositorySet && (
                  <div>
                    {!editRepositoryToken && (
                      <ButtonComponent
                        variant="outlined"
                        onClick={() => {
                          setEditRepositoryToken(true);
                        }}
                        sx={{
                          cursor: "pointer",
                          width: "max-content",
                          marginTop: "10px",
                        }}
                        text="Edit Repository Token"
                      />
                    )}
                    {editRepositoryToken && (
                      <ButtonComponent
                        variant="outlined"
                        onClick={() => {
                          setEditRepositoryToken(false);
                        }}
                        sx={{
                          cursor: "pointer",
                          marginRight: "15px",
                          marginTop: "10px",
                        }}
                        text="Cancel"
                      />
                    )}
                  </div>
                )}
                <TextField
                  autoComplete="off"
                  variant="standard"
                  sx={{
                    ...rootStyle,
                    ...(isUpdatingRepositoryToken ? {} : { display: "none" }),
                    marginRight: 0,
                    paddingRight: 0,
                  }}
                  fullWidth
                  label="Repository token"
                  type="password"
                  value={repositoryToken}
                  onChange={(event) => setRepositoryToken(event.target.value)}
                  helperText={repositoryTokenHelperText}
                  error={repositoryTokenError}
                />
              </div>
            ) : (
              <TextField
                autoComplete="off"
                variant="standard"
                sx={rootStyle}
                fullWidth
                label="Repository token"
                type="password"
                value={repositoryToken}
                onChange={(event) => setRepositoryToken(event.target.value)}
                helperText={repositoryTokenHelperText}
                error={repositoryTokenError}
              />
            )}
            <TextField
              data-testid="settings-input-fullname"
              variant="standard"
              sx={rootStyle}
              fullWidth
              label="Name"
              value={fullName}
              disabled={settings.isNameEmailFromKeycloak}
              onChange={(event) => setFullName(event.target.value)}
              helperText={fullNameHelperText}
              error={fullNameError}
            />
            <TextField
              data-testid="settings-input-email"
              variant="standard"
              sx={rootStyle}
              fullWidth
              label="Email"
              value={email}
              disabled={settings.isNameEmailFromKeycloak}
              onChange={(event) => setEmail(event.target.value)}
              helperText={emailHelperText}
              error={emailError}
            />
            <TextField
              variant="standard"
              sx={rootStyle}
              fullWidth
              label="Repository default branch"
              value={repositoryDefaultBranch}
              onChange={(event) =>
                setRepositoryDefaultBranch(event.target.value)
              }
              helperText={repositoryDefaultBranchHelperText}
              error={repositoryDefaultBranchError}
            />
            <TextField
              variant="standard"
              sx={rootStyle}
              fullWidth
              label="Repository workspace path"
              value={repositoryWorkspacePath}
              onChange={(event) =>
                setRepositoryWorkspacePath(event.target.value)
              }
              helperText={repositoryWorkspacePathHelperText}
              error={repositoryWorkspacePathError}
            />
            {isElectron && (
              <TextField
                variant="standard"
                sx={rootStyle}
                fullWidth
                label="Runtime server port (applied after restart)"
                value={runtimeServerPort || ""}
                onChange={(event) => setRuntimeServerPort(event.target.value)}
                helperText={runtimeServerPortHelperText}
                error={runtimeServerPortError}
              />
            )}
          </Box>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default DesignerSettings;
