import {
  ReferenceResolution,
  ResolvedSchemaObject,
  SchemaObject,
} from "../ReferenceResolution";
import { SchemaRepository } from "../../repositories";
import { BaseReferenceResolution } from "../BaseReferenceResolution";

type Ref = string;
type ResolutionPass = {
  schema: SchemaObject;
  refsThatHaveBeenFound: Ref[];
  refsThatNeedToBeResolved: Ref[];
};
type ResolvedRef = {
  ref: Ref;
  schema: SchemaObject;
};

export const REF_PREFIX = "#/components/schemas/";

export class ACE4ReferenceResolution
  extends BaseReferenceResolution
  implements ReferenceResolution
{
  public constructor(schemaRepository: SchemaRepository) {
    super(schemaRepository);
  }

  public async deepResolveSchema(
    schemaName: string
  ): Promise<SchemaObject | undefined> {
    const schema = await this.lookupSchema(schemaName);
    if (!schema) return;
    return this.deepResolveSchemaObject(schema);
  }

  public getOperationSchemaName(ref = ""): string {
    return ref.replace("#/components/schemas/", "");
  }

  public async deepResolveSchemaObject(
    schemaObject: SchemaObject
  ): Promise<SchemaObject> {
    let pass = this.doInitialPass(schemaObject);
    while (pass.refsThatNeedToBeResolved.length > 0) {
      pass = await this.doFollowingPass(pass);
    }
    return pass.schema;
  }

  private doInitialPass(schema: SchemaObject): ResolutionPass {
    const refs = this.extractRefs(schema);
    return {
      schema,
      refsThatHaveBeenFound: refs,
      refsThatNeedToBeResolved: refs,
    };
  }

  private extractRefs(schema: SchemaObject): Ref[] {
    const refs = new Set<Ref>();
    JSON.stringify(schema, (key, value) => {
      if (!this.isRef(key, value)) return value;
      refs.add(value);
    });
    return Array.from(refs);
  }

  private isRef(key: string, value: unknown): value is string {
    return (
      key === "$ref" &&
      typeof value === "string" &&
      value.startsWith(REF_PREFIX)
    );
  }

  private async doFollowingPass(
    previousPass: ResolutionPass
  ): Promise<ResolutionPass> {
    const pass = { ...previousPass };
    const resolved = await this.resolveRefs(pass.refsThatNeedToBeResolved);

    const newRefsThatNeedToBeResolved: Ref[] = [];
    resolved.forEach((resolved) => {
      const refs = this.extractRefs(resolved.schema);
      const newUniqueRefs = refs.filter(
        (ref) => !pass.refsThatHaveBeenFound.includes(ref)
      );
      pass.refsThatHaveBeenFound.push(...newUniqueRefs);
      newRefsThatNeedToBeResolved.push(...newUniqueRefs);
      this.placeReferencesIntoComponentsSchemas(pass.schema, resolved);
    });
    return { ...pass, refsThatNeedToBeResolved: newRefsThatNeedToBeResolved };
  }

  private placeReferencesIntoComponentsSchemas(
    baseSchema: SchemaObject,
    { ref, schema }: ResolvedRef
  ): void {
    const resolvedSchema = baseSchema as ResolvedSchemaObject;
    if (!resolvedSchema.components) resolvedSchema.components = {};
    if (!resolvedSchema?.components?.schemas)
      resolvedSchema.components.schemas = {};
    resolvedSchema.components.schemas[this.omitRefPrefix(ref)] = schema;
  }

  private async resolveRefs(refs: Ref[]): Promise<ResolvedRef[]> {
    const schemas = await Promise.all(
      refs.map(async (ref) => {
        const schemaName = this.omitRefPrefix(ref);
        const schema: SchemaObject | undefined = await this.lookupSchema(
          schemaName
        );
        if (!this.isObject(schema)) return;
        return { ref, schema };
      })
    );

    const validSchemas: ResolvedRef[] = [];
    schemas.forEach((schema) => {
      if (!schema) return;
      validSchemas.push(schema);
    });
    return validSchemas;
  }

  private omitRefPrefix(ref: Ref): Ref {
    return ref.slice(REF_PREFIX.length);
  }

  private isObject(value: unknown): value is SchemaObject {
    return typeof value === "object" && value !== null;
  }
}
