import { SettingsManager } from "@sapiens-digital/ace-designer-app/app/services/settingsManager";

export const closeWindowAfterRemovingAuthDetails = (): void => {
  clearAuthDetailsFromLocalStore();
  window.close();
};

export const getTokenFromLocalStorage = (): string | null =>
  localStorage.getItem("Authorization");

export const getUserFromLocalStorage = (): string | null =>
  localStorage.getItem("User");

export const clearAuthDetailsFromLocalStore = (): void => {
  localStorage.removeItem("Authorization");
  localStorage.removeItem("User");
};

export const isLogoutAction = (): boolean =>
  new URLSearchParams(window.location.search).get("action") === "logout";

export const getIdpAuthUrl = (): string => {
  return `/auth/${SettingsManager.getDesignerConfigs().authStrategy}`;
};

export const isAuthenticationEnabled = (): boolean => {
  return Boolean(SettingsManager.getDesignerConfigs().authStrategy);
};
