import { JSONSchema6 } from "json-schema";
import { UiSchema } from "react-jsonschema-form";

export const REGX_FOR_VALID_STRING_EXPRESSION = "^$|^{{.*}}$|^@[a-z]+{{.*}}";

export interface StepSchema {
  stepType: string;
  color?: string;
  jsonSchema: JSONSchema6;
  uiSchema: UiSchema;
  hideForAce4?: boolean;
}

/**
 * Core steps
 */
export enum Steps {
  DATE = "date",
  LOOKUP = "lookup",
  CLEAN_OBJECT = "clean-object",
  MIXEDFLOW = "mixedflow",
  MULTIFLOW = "multiflow",
  TRANSFORM = "transform",
  SOAP = "soap",
  MONGODB = "mongo-db",
  SOAP_PURE = "soap-pure",
  JSON_RPC = "jsonrpc",
  REST = "rest",
  REST_NEW = "rest-new",
  JSONATA = "jsonata",
  MAP = "map",
  CODE = "code",
  CODE_IVM = "code-ivm",
  XML = "xml",
  JWT = "jwt",
  DEEP_MERGE = "deep-merge",
  AJV = "ajv",
  FILE_REFERENCE = "file-reference",
  SOAP_NEW = "soap-new",
  CATCH = "catch",
  LOG = "log",
  SAPIQDB = "sapiq-db",
  ORACLEDB = "oracle-db",
  INPUT_TRANSFORM = "input-transform",
  RESPONSE_VALIDATION = "resp-validation",
  REDIS = "redis",
  IBM_DB = "ibm-db2",
  MSSQL_DB = "mssql-db",
  CLEAR_CACHE = "clear-cache",
  REQUEST_VALIDATION = "request-validation",
  STOP_STEP = "stop",
  SWITCH = "switch",
  VIRTUAL = "virtual",
  CONDITION = "condition",
}
