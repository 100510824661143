import { DEFAULT_GIT_AUTHOR } from "@sapiens-digital/ace-designer-app/app/constant/uiConstants";
import {
  RepositoryAccessFailure,
  UserAuthorSettings,
  UserRepositorySettings,
  UserSettings,
} from "@sapiens-digital/ace-designer-app/app/model";
import { WorkspaceSettings } from "@sapiens-digital/ace-designer-app/app/model/workspace";
import {
  GetEnvironmentVariables,
  LoadDesignerSettings,
  SaveDesignerSettings,
} from "@sapiens-digital/ace-designer-app/app/services/settings";
import { SettingsManager } from "@sapiens-digital/ace-designer-app/app/services/settingsManager";
import {
  getAuthorizationHeader,
  getAuthorizationUser,
} from "@sapiens-digital/ace-designer-app/app/store/utils/getAuthorization";
import { DesignerConfig } from "@sapiens-digital/ace-designer-common/lib/model/designerSettings";
import assert from "assert";

import { LoadWorkspaceSettings, SaveWorkspaceSettings } from "./workspace";

const ACE_DESIGNER_SETTINGS = "ACE_DESIGNER_SETTINGS";
const ACE_DESIGNER_WORKSPACES = "/";
const ACE_WORKSPACE_SETTINGS = "ACE_WORKSPACE_SETTINGS";

export const DEFAULT_SETTINGS: UserSettings = {
  workspacesLocation: ACE_DESIGNER_WORKSPACES,
  repositoryWorkspacePath: "/src/ace",
  repositoryDefaultBranch: "develop",
  fullName: DEFAULT_GIT_AUTHOR.name,
  email: DEFAULT_GIT_AUTHOR.email,
  isNameEmailFromKeycloak: false,
};

const DEFAULT_WORKSPACE_SETTINGS: WorkspaceSettings = {
  workspaces: [],
};

function getAuthorizedUser(): UserAuthorSettings {
  const { name, email } = getAuthorizationUser();

  return {
    fullName: name?.length ? name : DEFAULT_SETTINGS.fullName,
    email: email?.length ? email : DEFAULT_SETTINGS.email,
    isNameEmailFromKeycloak: true,
  };
}

export const loadDesignerSettings: LoadDesignerSettings = async () => {
  const config = SettingsManager.getDesignerConfigs();
  return config?.featurePersistentSettings
    ? await loadBackendDesignerSettings()
    : await loadLocalDesignerSettings();
};

export const saveDesignerSettings: SaveDesignerSettings = async (
  settings: UserSettings
) => {
  const config = SettingsManager.getDesignerConfigs();
  return config?.featurePersistentSettings
    ? await saveBackendDesignerSettings(settings)
    : await saveLocalDesignerSettings(settings);
};

const loadLocalDesignerSettings: LoadDesignerSettings = async () => {
  const settings = { ...DEFAULT_SETTINGS, ...getSettingsFromLocalStorage() };
  await saveDesignerSettings(settings);
  return settings;
};

const saveLocalDesignerSettings: SaveDesignerSettings = async (
  settings: UserSettings
) => {
  localStorage.setItem(ACE_DESIGNER_SETTINGS, JSON.stringify(settings));
  return undefined;
};

const loadBackendDesignerSettings: LoadDesignerSettings = async () => {
  try {
    const userAuthorSettings = getAuthorizedUser();

    const configRes = await fetch("/api/config/user", {
      method: "GET",
      headers: { authorization: getAuthorizationHeader() },
    });

    if (!configRes.ok) {
      throw await configRes.json();
    }

    const userConfig = await configRes.json();
    const { selectedWorkspaceId } = getSettingsFromLocalStorage();
    const userSettings: UserSettings = {
      ...DEFAULT_SETTINGS,
      ...userConfig,
      ...userAuthorSettings,
      selectedWorkspaceId,
    };
    return userSettings;
  } catch (e) {
    console.error(`Failed to fetch user settings`, e);
    window.dispatchEvent(new CustomEvent("loadSettingsFail"));
    //TODO: throw error, returning default settings seems wrong
    return DEFAULT_SETTINGS;
  }
};

type PartialError = { metadata?: RepositoryAccessFailure };

const saveBackendDesignerSettings: SaveDesignerSettings = async (
  settings: UserSettings
) => {
  const { selectedWorkspaceId } = settings;
  const partialSettings = { selectedWorkspaceId };
  localStorage.setItem(ACE_DESIGNER_SETTINGS, JSON.stringify(partialSettings));

  try {
    assert(settings.repositoryUrl, "Repository url must be defined");

    const userSettings: UserRepositorySettings = {
      repositoryDefaultBranch: settings.repositoryDefaultBranch,
      repositoryToken: settings.repositoryToken,
      repositoryUrl: settings.repositoryUrl,
      repositoryUsername: settings.repositoryUsername,
      repositoryWorkspacePath: settings.repositoryWorkspacePath,
    };

    const configRes = await fetch("/api/config/user", {
      method: "POST",
      headers: {
        authorization: getAuthorizationHeader(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userSettings),
    });

    if (!configRes.ok) {
      const error = (await configRes.json()) as PartialError;
      console.warn(error);
      return error.metadata ?? RepositoryAccessFailure.Unknown;
    }
  } catch (e) {
    console.warn(`Failed to save user settings`, e);
    return RepositoryAccessFailure.Unknown;
  }
};

const getSettingsFromLocalStorage = (): Partial<UserSettings> => {
  const serialized = localStorage.getItem(ACE_DESIGNER_SETTINGS);
  return serialized ? JSON.parse(serialized) : {};
};

export const loadWorkspaceSettings: LoadWorkspaceSettings = async () => {
  const serialized = localStorage.getItem(ACE_WORKSPACE_SETTINGS);
  if (serialized) return JSON.parse(serialized);

  await saveWorkspaceSettings(DEFAULT_WORKSPACE_SETTINGS);
  return DEFAULT_WORKSPACE_SETTINGS;
};

export const saveWorkspaceSettings: SaveWorkspaceSettings = async (
  settings
) => {
  localStorage.setItem(ACE_WORKSPACE_SETTINGS, JSON.stringify(settings));
};

export const getEnvVariables: GetEnvironmentVariables = async () => {
  const configRes = await fetch("/config");
  const config: DesignerConfig = await configRes.json();
  return {
    ...process.env,
    ...config,
  } as DesignerConfig;
};
